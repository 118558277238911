/* global $ */

const scrollOffset = 72;
const scrollTime = 400;

$('body')
  .on('click', 'a.scroll-read-more', function(e) {
    e.preventDefault();
    const scrollTop = $($(this).attr('href')).offset().top - scrollOffset;
    $('html, body').animate({ scrollTop }, scrollTime);
  })
  .on('click', '.smooth_scroll', function(e) {
    e.preventDefault();
    const $target = $($(this).attr('href')); // usually a #hash selector...

    if ($target.length) {
      const scrollTop = $target.offset().top - scrollOffset;

      // Animate
      $('html, body')
        .stop()
        .animate({ scrollTop }, scrollTime);
    }
  });
