/* global $ */

// Search
const searchType = $('body').data('searchtype');

if (searchType === 'pageOverlay') {
  const openSearch = (e) => {
    e.preventDefault();
    $('.searchOverlay').addClass('search--open temp-high-z');
    $('.search__input').focus();
    $('html').addClass('noScroll');
    $('.searchOverlay').attr('aria-hidden', 'false');
  };
  const closeSearch = (e) => {
    $('html').removeClass('noScroll');
    e.preventDefault();
    $('.searchOverlay').removeClass('search--open');
    $('.search__input').blur();
    $('.search__input').val('');
    $('.searchOverlay').attr('attr-hidden', 'true');
    setTimeout(() => {
      $('.searchOverlay').removeClass('temp-high-z');
    }, 100);
  };

  $('body').on('click', '#btn-search', openSearch);
  $('body').on('click', '#btn-search-close', closeSearch);

  document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27) {
      closeSearch(e);
    }
  });
} else {
  // pulldown search
  $('body')
    .on('click', '#btn-search, #search-trigger', (e) => {
      e.preventDefault();
      e.stopPropagation();
      $('.top-search .focus-input').focus();
      $('body').toggleClass('search-open');
    })
    .on('keyup', '.top-search .focus-input', () => {
      $('.top-search input[type="submit"]').css('opacity', '1');
    });

  document.addEventListener('keyup', (e) => {
    if (e.keyCode === 27) {
      $('body').removeClass('search-open temp-high-z');
    }
  });

  $(document).on('click', (e) => {
    const searchWrap = $('.top-search');
    if (!searchWrap.is(e.target) && searchWrap.has(e.target).length === 0) {
      $('body').removeClass('search-open temp-high-z');
    }
  });
}

// Prevent empty searches on older browsers
$('body').on('submit', '[data-searchform]', (e) => {
  const searchInputValue = $(e.target)
    .find('[name="s"]')
    .val();
  if (searchInputValue === '') {
    e.preventDefault();
  }
});
