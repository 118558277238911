/* global $ */

import { throttleDomAfterAsync } from '../helpers/throttle';

const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $actionNav = $('body').find('.action-menu');
const $window = $('window');
const $alertBar = $('body').find('.alert-bar'); // eslint-disable-line no-unused-vars
let alertBarHeight = $alertBar.height(); // eslint-disable-line no-unused-vars

$(document).on('keydown', (e) => {
  if (e.keyCode === 27) {
    // window.open('', '_self', ''); window.close();
  }
});

if ($('.action-menu').length > -1) {
  const checkSecondaryAlertOverlap = throttleDomAfterAsync((e = {}) => {
    const { target = {} } = e;
    let { scrollingElement: { scrollTop } = {} } = target;
    if (!scrollTop) {
      scrollTop = $window.scrollTop();
    }
    if ($('.alert-bar').is(':visible')) {
      alertBarHeight = $alertBar.height(); // eslint-disable-line no-unused-vars
    } else {
      alertBarHeight = 0; // eslint-disable-line no-unused-vars
    }

    $actionNav.toggleClass('animate', scrollTop >= alertBarHeight);
  }, scrollEffectDelay);

  $(window).on('scroll resize', checkSecondaryAlertOverlap);
  checkSecondaryAlertOverlap();
}
