/* global $ */

// import { throttleDomAfterAsync } from '../helpers/throttle';

// const scrollEffectDelay = 70; // delay any scroll events by this many MS
const $modules = $('body').find('.module');

// add class to last module
$modules.last().addClass('last-module');

// const checkActiveSections = throttleDomAfterAsync(() => {
//   $modules.each((i, el) => {
//     const $el = $(el);
//     const isVisible = $el.visible(true);
//     if (isVisible && !$el.hasClass('seenSection')) {
//       $el.addClass('seenSection');
//     }
//     $el.toggleClass('activeSection', isVisible);
//   });
// }, scrollEffectDelay);

// $(window).on('scroll', checkActiveSections);

// Landing page
$('body')
  .on('mouseenter', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '1');
  })
  .on('mouseleave', '.scroll', function() {
    $(this)
      .next('.tool')
      .css('opacity', '0');
  })
  .on('click', '.scroll', function(e) {
    e.preventDefault();
    const scrollAnchor = $(this).data('scroll');
    const $scrollToModule = $(
      `.module[data-anchor="${scrollAnchor}"], #${scrollAnchor}`,
    ).eq(0);

    if ($scrollToModule.length) {
      const scrollTop = $scrollToModule.offset().top - 60;
      if (scrollTop) {
        $('html, body').animate({ scrollTop }, 400);
      }
    }
  });
