/* global $ */

$('body')
  .find('.image-slider')
  .slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

$('body')
  .find('.campaigns__list-item')
  .click(function(e) {
    e.preventDefault();
    $(this).blur();
    if (!$(this).hasClass('active')) {
      $('.campaigns__list-item.active').removeClass('active');
      $(this).addClass('active');

      const key = $(this).data('key');
      $('.campaign__detail.active').removeClass('active');
      $('#slider__item-' + key).addClass('active');
    }
  });
