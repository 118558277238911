/* eslint no-unused-vars: 0  */
/* global $, ScrollMagic */

// const animationBreakpoint = 960; // see matching breakpoint in global/effects/animations.scss
const hasIOSupport =
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype;

// polyfill for browsers that do not support IOs
const nodes = [];

// addClassOnScrollTo ::  DOMNode -> null
const addClassOnScrollTo = (className) => (node, threshold = 0) => {
  const { height, top } = node.getBoundingClientRect();
  const { scrollY, innerHeight, pageYOffset } = window;
  const scrollPos = typeof scrollY !== 'undefined' ? scrollY : pageYOffset;

  const topEdge = scrollPos + top + height * threshold;

  nodes.push({
    topEdge,
    height,
    node,
    className,
    threshold,
  });
};

const standardDelay = 0.5;

// this adds staggered animation delays to each matching child element
// so that they can animate in on demand
$('[data-animate-children]').each((i, el) => {
  const $el = $(el);
  const childSelector = $(el).data('animate-children');
  const delay = $(el).data('animate-children-delay');

  $el
    .find(childSelector)
    .addClass('js-animated-child')
    .each((index, child) => {
      $(child).css({
        animationDelay: Number(delay || standardDelay) * (0 + index) + 's',
      });
    });
});

// intersection observer-based section classes to trigger animations
const addClass = (className) => (entries, observer) => {
  if (entries.length && entries[0].intersectionRatio <= 0) {
    return;
  }
  entries.forEach((entry) => {
    const $target = $(entry.target).removeClass('activeSection');
    if (entry.isIntersecting) {
      $target.addClass(`${className} activeSection`);
      // observer.disconnect();
    }
  });
};

const addClassAtThreshold = (module, className = '', threshold = 0.15) => {
  if (hasIOSupport) {
    const observer = new IntersectionObserver(addClass(className), {
      rootMargin: '0px',
      threshold: [threshold, 1 - threshold], // fire on top and bottom edges
    });
    observer.observe(module);
  } else {
    // alternatively, we could add these to a polyfill...
    // but the browser share of desktop safari and oldIE is pretty tiny....
    // $(module).addClass(className);
    addClassOnScrollTo(className)(module, threshold);
  }
};

const defaultModuleThreshold = 0.2;

$('[data-module]').each((i, thisModule) => {
  const maxThreshold = window.innerHeight / thisModule.offsetHeight;
  let threshold =
    $(thisModule).data('module-seen-threshold') || defaultModuleThreshold;
  if (threshold > maxThreshold) {
    threshold = maxThreshold;
  }
  addClassAtThreshold(thisModule, 'seenSection', threshold);
});

const defaultAnimateChildThreshold = 0.1;

$('[data-animate-children]').each((i, thisModule) => {
  const maxThreshold = window.innerHeight / thisModule.offsetHeight;
  let threshold =
    $(thisModule).data('module-seen-threshold') || defaultAnimateChildThreshold;
  if (threshold > maxThreshold) {
    threshold = maxThreshold;
  }
  addClassAtThreshold(thisModule, 'animate-children', threshold);
});

if (!hasIOSupport && nodes.length) {
  const $window = $(window);

  const scrollHandler = (e) => {
    const { scrollY, innerHeight, pageYOffset } = window;
    const scrollPos = typeof scrollY !== 'undefined' ? scrollY : pageYOffset;

    nodes.forEach((nodeWatcher) => {
      const { node, className, topEdge, bottomEdge, height } = nodeWatcher;
      const $node = $(node).removeClass('activeSection');
      if (topEdge < scrollPos + innerHeight && topEdge + height > scrollPos) {
        $node.addClass(`${className} activeSection`);
        nodes.splice(nodes.indexOf(nodeWatcher), 1);
      }
    });
    if (!nodes.length) {
      $window.off('scroll', scrollHandler);
    }
  };

  // recreate nodeList with new heights
  $window.on('resize', (e) => {
    nodes.forEach((nodeWatcher) => {
      const { node, className, threshold } = nodeWatcher;
      nodes.splice(nodes.indexOf(nodeWatcher), 1); // remove from the array
      addClassOnScrollTo(className)(node, threshold);
    });
  });
  $window.on('scroll', scrollHandler).scroll();
  setTimeout(() => $window.scroll(), 1000);
}
