/* global $ */

$('.searchandfilter select').selectric({
  disableOnMobile: false,
  nativeOnMobile: false,
});

$('.searchandfilter').append('<button type="submit"></button>');
$('.searchandfilter [type="submit"]').hide();

$('.searchandfilter select').on('selectric-change', () => {
  setTimeout(() => {
    $('.searchandfilter [type="submit"]').click();
  }, 0);
});
