/* global $ */

// NGP
if ($('.ngp-code.add-callback').length > -1) {
  // const ngpCallback = () => {
  //   $('.ngp-code.add-callback').each(function() {
  //     $(this)
  //       .find('label.EmailAddress')
  //       .addClass('show');
  //     $(this)
  //       .find('label.EmailAddress input')
  //       .attr('placeholder', 'Your Email');
  //     $(this)
  //       .find('label.PostalCode')
  //       .addClass('show');
  //     $(this)
  //       .find('label.PostalCode input')
  //       .attr('placeholder', 'Zip Code');
  //   });
  // };
  // strange & worrisome that this is necessary
  // $(window).bind('load', () => {
  //   setTimeout(ngpCallback, 500);
  //   setTimeout(ngpCallback, 1000);
  //   setTimeout(ngpCallback, 2000);
  // });
}
