/* global $, Cookies */

$('body')
  .find('.video-popup')
  .magnificPopup({
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
  });

$('body')
  .find('.popup-youtube')
  .magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,
    fixedContentPos: false,
  });

// Modal Popups. Global/page using Magnific Popup plugin.
//----------------------------------------------------------------------------
if ($('.pop-over').length > 0) {
  /**
   * Depending on the type of modal used (if any), initializes it
   * accordingly.
   *
   * @return {void}
   */
  const initializeModal = () => {
    $('.pop-over').addClass('is-visible');
    if ($('.pop-over').hasClass('image')) {
      // Image modal.
      if ($('#image-popup').length > 0) {
        $('#image-popup').magnificPopup({
          type: 'image',
          closeOnContentClick: true,
          mainClass: 'mfp-img-mobile',
          image: { verticalFit: true },
          callbacks: {
            open: () => {
              $('body').removeClass('is-splashed');
            },
          },
        });
        $('#image-popup').click();
      } else {
        $('#image-popup-linkable').magnificPopup({
          type: 'inline',
          preloader: false,
          callbacks: {
            open: () => {
              $('body').removeClass('is-splashed');
            },
          },
        });
        $('#image-popup-linkable').click();
      }
    } else if ($('.pop-over').hasClass('video')) {
      // Video modal.
      $('body').removeClass('is-splashed');
      $('.pop-over.video').on('click', () => {
        $('.pop-over.video').fadeOut();
      });
      if ($('.pop-over.video .play-row').data('autoplay') === true) {
        $('.pop-over.video [data-fancybox]').click();
      }
    } else if ($('.pop-over').hasClass('html')) {
      // HTML modal.
      $('#inline-popup-link').magnificPopup({
        type: 'inline',
        preloader: false,
        callbacks: {
          open: () => {
            $('body').removeClass('is-splashed');
          },
        },
      });
      $('#inline-popup-link').click();
    } else if ($('.pop-over').hasClass('bsd')) {
      // BSD modal.
      $('#bsd-popup-link').magnificPopup({
        type: 'inline',
        preloader: false,
        callbacks: {
          open: () => {
            $('body').removeClass('is-splashed');
          },
        },
      });
      $('#bsd-popup-link').click();
    }
  };

  // Show or don't show modal based on cookie logic.
  const $popupOptions = $('.js-popup-options');
  const frequency = $popupOptions.data('frequency');
  const cookieName = $popupOptions.data('cookie-name');
  if (!Cookies.getJSON(cookieName) || frequency === 'always') {
    initializeModal();
    if (frequency !== 'always') {
      if (frequency === 'session') {
        Cookies.set(cookieName, 'shown');
      } else {
        const cookieLengths = { week: 7, once: 999 };
        Cookies.set(cookieName, 'shown', {
          expires: cookieLengths[frequency],
        });
      }
    }
  }
}
